import { box } from './box';
import { heading } from './heading';
import { paragraph } from './paragraph';
import { anchor } from './anchor';
import { button } from './button';
import { icon } from './icon';
import { list } from './list';
import { avatar } from './avatar';
import { carousel } from './carousel';
import { checkBox } from './checkBox';
import { radioButton } from './radioButton';
import { accordion } from './accordion';
import { layer } from './layer';
import { calendar } from './calendar';
import { formField } from './formField';
import { page } from './page';
import { toggleGroup } from './toggleGroup';
import { notification } from './notification';
import {
  colors,
  font,
  logo,
  text,
  edgeSize,
  borderSize,
  breakpoints,
  elevation,
  focus,
  control,
  input,
  drop
} from './global';

export default {
  global: {
    colors,
    font,
    logo,
    edgeSize,
    borderSize,
    breakpoints,
    elevation,
    focus,
    control,
    input,
    drop
  },
  box,
  text,
  heading,
  paragraph,
  anchor,
  button,
  icon,
  list,
  avatar,
  carousel,
  checkBox,
  radioButton,
  accordion,
  layer,
  calendar,
  formField,
  page,
  toggleGroup,
  notification
};
