const sizes = {
  xsmall: {
    size: '16px',
    height: '22px',
  },
  small: {
    size: "18px",
    height: "26px"
  },
  medium: {
    size: "24px",
    height: "32px"
  },
  large: {
    size: "30px",
    height: "40px"
  },
  xlarge: {
    size: "36px",
    height: "42px"
  }
};

export const heading = {
  weight: 500,
  color: 'heading',
  responsiveBreakpoint: 'undefined', // This avoids us dropping down to the next level on mobile
  level: {
    1: sizes,
    2: sizes,
    3: sizes,
    4: sizes,
    5: sizes,
    6: sizes
  }
};