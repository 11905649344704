export const toggleGroup = {
  button: {
    pad: {
      horizontal: 'large',
      vertical: '6px',
    },
  },
  container: {
    round: '3px',
    border: false,
    background: 'grey-11',
  },
  divider: {
    color: 'FFFFFF00',
  },
};
