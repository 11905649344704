export const text = {
  '6xl': {
    size: '144px',
    height: '164px',
  },
  '5xl': {
    size: '120px',
    height: '140px',
  },
  '4xl': {
    size: '96px',
    height: '116px',
  },
  '3xl': {
    size: '72px',
    height: '82px',
  },
  '2xl': {
    size: '48px',
    height: '58px',
  },
  xxlarge: {
    size: '36px',
    height: '46px',
  },
  xlarge: {
    size: '30px',
    height: '40px',
  },
  large: {
    size: '24px',
    height: '32px',
  },
  medium: {
    size: '18px',
    height: '26px',
  },
  small: {
    size: '16px',
    height: '22px',
  },
  xsmall: {
    size: '14px',
    height: '18px',
  },
};
