export const edgeSize = {
  responsiveBreakpoint: 'small',
  xlarge: '64px',
  large: '48px',
  medium: '36px',
  small: '24px', // this stops busy indicator
  xsmall: '16px',
  xxsmall: '8px',
  hair: '1px',
};
