import { css, FlattenSimpleInterpolation } from 'styled-components';

export const box = {
  responsiveBreakpoint: 'small', // when we switch rows to columns
  extend: (): FlattenSimpleInterpolation => css`
    min-height: auto;
  `,
  border: {
    radius: {
      xsmall: '2px',
      small: '3px',
      medium: '4px',
      large: '6px',
      xlarge: '8px',
      xxlarge: '15px',
      full: '50%',
    },
  },
};
