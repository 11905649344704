export const radioButton = {
  gap: 'xxsmall',
  border: {
    color: 'grey-6',
    width: '1px',
  },
  color: 'grey-6', // checked border color
  check: {
    color: 'grey-6',
  },
  hover: {
    border: {
      color: 'grey-6',
    },
  },
  icon: {
    size: '16px',
  },
  size: '18px',
};
