export const notification = {
  warning: {
    background: 'FFFFFF00',
    color: 'status-critical'
  },
  container: {
    pad: false,
    border: false,
  },
  iconContainer: {
    pad: { right: '8px' }
  },
  textContainer: {
    alignSelf: 'center'
  },
};