export const control = {
  border: {
    width: '1px',
    radius: '6px',
    color: { light: '#929292', dark: '#929292' },
  },
  disabled: {
    opacity: 0.5,
  },
};
