export const accordion = {
  panel: {
    border: {
      color: 'transparent',
    },
  },
  border: {
    color: 'transparent',
  },
  icons: {
    collapse: undefined,
    expand: undefined,
  },
};
