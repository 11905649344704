import { css } from 'styled-components';

export const font = {
  links: [],
  family: 'Helvetica, Arial, sans-serif',
  size: '16px',
  height: '22px',
  weight: 400,
  secondary: {
    family: css`${({ theme }) => theme.global.font['family']}`,
    style: 'normal',
    size: css`${({ theme }) => theme.global.font['size']}`,
    height: css`${({ theme }) => theme.global.font['height']}`,
  }
};
