import { useContext } from 'react';
import { ThemeContext } from 'grommet';
import { ColorType } from 'grommet/utils';
import { darken, lighten } from 'polished';

import {
  ExtendedThemeType,
  EdgeTshirtSizesType,
  FullTshirtSizesType,
  fullTshirtSizes,
  ShortTshirtSizesType,
  TGrommetColor,
  TDropThemableProps,
  shortTshirtSizes,
} from '../types/grommet';
import { useIsMobile } from '../hooks';

type SizeDuple = {
  str: string;
  int: number;
};

export const pxToInt = (pxVal: string): number => parseInt(pxVal?.substring(0, pxVal.length - 2));

export const useHexColor = (color: ColorType): string | undefined => {
  const theme: ExtendedThemeType = useContext(ThemeContext);
  const modeKey = theme?.dark ? 'dark' : 'light';

  if (!color) return;

  if (typeof color === 'string') {
    if (color.startsWith('#')) return color;
    else return useHexColor(theme?.global?.colors?.[color]);
  }

  return useHexColor(color?.[modeKey]);
};

export const getNextSizeUp = (size: ShortTshirtSizesType): ShortTshirtSizesType => {
  return shortTshirtSizes[shortTshirtSizes.indexOf(size) + 1] ?? size;
};

export const getGlobalColor = (
  theme: ExtendedThemeType,
  alias?: TGrommetColor,
): string | undefined => {
  if (!alias) return undefined;

  if (alias.startsWith('#')) return alias;

  const color: ColorType = theme?.global?.colors?.[alias];

  if (typeof color === 'string') return color;
  else return color?.[theme?.dark ? 'dark' : 'light'] ?? 'green';
};

export const getTint = (theme: ExtendedThemeType, dark?: boolean): number =>
  theme?.global?.[dark ? 'tint-dark' : 'tint-light'] ?? 0.075;

export const useGlobalColor = (
  alias?: TGrommetColor,
  tint?: 'dark' | 'light',
): string | undefined => {
  const theme: ExtendedThemeType = useContext(ThemeContext);

  const baseColor = getGlobalColor(theme, alias);

  if (baseColor && tint === 'dark') return darken(getTint(theme, true), baseColor);
  if (baseColor && tint === 'light') return lighten(getTint(theme, true), baseColor);

  return getGlobalColor(theme, alias);
};

export const useTint = (dark?: boolean): number => {
  const theme: ExtendedThemeType = useContext(ThemeContext);

  return getTint(theme, dark);
};

export const useAvatarSize = (
  alias: ShortTshirtSizesType,
): { image: SizeDuple; text: SizeDuple } => {
  const theme: ExtendedThemeType = useContext(ThemeContext);
  const imageStrSize = theme?.avatar?.size?.[alias] ?? '0px';
  const textStrSize = theme?.avatar?.text?.size?.[alias] ?? '0px';

  return {
    image: { int: pxToInt(imageStrSize), str: imageStrSize },
    text: { int: pxToInt(textStrSize), str: textStrSize },
  };
};

export const useEdgeSize = (edgeSize: EdgeTshirtSizesType | string): SizeDuple => {
  const theme: ExtendedThemeType = useContext(ThemeContext);

  if (edgeSize?.endsWith('px')) return { str: edgeSize, int: pxToInt(edgeSize) };

  const alias = edgeSize as ShortTshirtSizesType;

  // TODO: Add mobile size support
  const pxSize = theme?.global?.edgeSize?.[alias] ?? '0px';

  return { str: pxSize, int: pxToInt(pxSize) };
};

export const getTransformOriginStyle = (align: TDropThemableProps['alignProp']): string => {
  let style = '';

  if (align?.top || align?.bottom) {
    if (align?.bottom) {
      style = style + 'bottom';
    } else {
      style = style + 'top';
    }
  }

  if (align?.left || align?.right) {
    style = style + ' ';

    if (align?.right) {
      style = style + 'right';
    } else {
      style = style + 'left';
    }
  }

  return style;
};

export const useBoxBorderRadius = (
  size?: ShortTshirtSizesType | 'full' | string,
): string | undefined => {
  const theme: ExtendedThemeType = useContext(ThemeContext);
  const isMobile = useIsMobile();

  if (size?.endsWith('px')) return size;

  const mobileSizeMap: Record<
    ShortTshirtSizesType | 'xxlarge' | 'full',
    ShortTshirtSizesType | 'full'
  > = {
    xsmall: 'xsmall',
    small: 'xsmall',
    medium: 'small',
    large: 'medium',
    xlarge: 'large',
    xxlarge: 'xlarge',
    full: 'full',
  };

  const alias = size as ShortTshirtSizesType | 'full';

  if (size) return theme?.box?.border?.radius?.[isMobile ? mobileSizeMap[alias] : alias];
};
