import { css } from 'styled-components';

export const elevation = {
  light: {
    none: 'none',
    xsmall: '2px 2px 1px rgba(0, 0, 0, 0.20)',
    small: '0px 2px 4px rgba(0, 0, 0, 0.20)',
    medium: '0px 4px 8px rgba(0, 0, 0, 0.20)',
    large: '0px 8px 16px rgba(0, 0, 0, 0.20)',
    xlarge: '0px 12px 24px rgba(0, 0, 0, 0.20)',
    glow: '0 0 14px 0 rgba(0,0,0,0.24)',
    selected: css`0 0 4px 2px ${({ theme }) => {
      const color = theme.global.colors['accent-1'];
      return typeof color === 'object' ? color[theme.dark ? 'dark' : 'light'] : color;
    }}`,
  },
  dark: {
    none: 'none',
    xsmall: '2px 2px 1px rgba(0,0,0,0.25)',
    small: '0px 4px 4px rgba(0,0,0,0.25)',
    medium: '0px 6px 8px rgba(0,0,0,0.25)',
    large: '0px 8px 16px rgba(0,0,0,0.25)',
    xlarge: '0px 12px 24px rgba(0,0,0,0.25)',
    glow: '0 0 14px 0 rgba(0,0,0,0.24)',
    selected: css`0 0 4px 2px ${({ theme }) => {
      const color = theme.global.colors['accent-1'];
      return typeof color === 'object' ? color[theme.dark ? 'dark' : 'light'] : color;
    }}`,
  },
};
