import { css, FlattenSimpleInterpolation } from 'styled-components';

export const calendar = {
  day: {
    extend: (): FlattenSimpleInterpolation => css`
      color: 'blue';
    `,
  },
  // daySize must align with global.size
  small: {
    // fontSize: `${baseFontSize - fontScale}px`,
    lineHeight: 1.375,
    // daySize: `${(baseSpacing * 8) / 7}px`,
    slideDuration: '0.2s',
  },
  medium: {
    // fontSize: `${baseFontSize}px`,
    lineHeight: 1.45,
    // daySize: `${(baseSpacing * 16) / 7}px`,
    slideDuration: '0.5s',
  },
  large: {
    // fontSize: `${baseFontSize + 3 * fontScale}px`,
    lineHeight: 1.11,
    // daySize: `${(baseSpacing * 32) / 7}px`,
    slideDuration: '0.8s',
  },
  heading: { level: '4' }, // level ranges from 1-6
};
