
import { css, FlattenSimpleInterpolation } from 'styled-components';

export const paragraph = {
  extend: (): FlattenSimpleInterpolation => css`
    white-space: pre-wrap;
  `,
  xxlarge: {
    size: '36px',
    height: '46px',
    maxWidth: undefined
  },
  xlarge: {
    size: '30px',
    height: '40px',
    maxWidth: undefined
  },
  large: {
    size: '24px',
    height: '32px',
    maxWidth: undefined
  },
  medium: {
    size: '18px',
    height: '26px',
    maxWidth: undefined
  },
  small: {
    size: '16px',
    height: '22px',
    maxWidth: undefined
  },
  xsmall: {
    size: '14px',
    height: '18px',
    maxWidth: undefined
  },
}