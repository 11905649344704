export const input = {
  padding: {
    horizontal: '11px',
    top: '16px',
    bottom: '16px',
  },
  font: {
    weight: 300,
  },
  tip: {
    offset: '5px',
  },
  icon: {
    padding: {
      left: '48px',
      right: '48px',
    },
  },
  error: {
    bottom: '-17px',
  },
};
