import { css, FlattenSimpleInterpolation } from 'styled-components';

import { TDropThemableProps } from '@lwe/toolkit/theming/types/grommet';
import { getTransformOriginStyle } from '@lwe/toolkit/theming/utils';

export const drop ={
  // intelligentMargin: undefined,
  background: 'background',
  border: {
    radius: '4px',
  },
  // margin: undefined
  // shadowSize: 'small', // shadowSize is deprecated, use 'elevation'
  elevation: 'small',
  zIndex: '300',
  extend: ({ alignProp }: TDropThemableProps): FlattenSimpleInterpolation =>
    css`
      transform-origin: ${getTransformOriginStyle(alignProp)};
    `,
};