export const icon = {
  size: {
    xsmall: '18px',
    small: '20px',
    medium: '22px',
    large: '28px',
    xlarge: '96px',
  },
  extend: undefined,
};
