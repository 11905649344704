import { css, FlattenSimpleInterpolation } from 'styled-components';

import { CheckboxThemableTProps } from '@lwe/toolkit/theming/types/grommet';
import { getGlobalColor } from '@lwe/toolkit/theming/utils';

export const checkBox = {
  border: {
    color: 'grey-6',
    width: '1px',
  },
  color: '#fbfbfb', // checked icon color (inc. toggle)
  hover: undefined,
  check: {
    thickness: '2px',
    extend: ({
      checked,
      indeterminate,
      theme,
    }: CheckboxThemableTProps): FlattenSimpleInterpolation => css`
      background: ${checked || indeterminate ? getGlobalColor(theme, 'grey-6') : 'none'};
      border-color: ${getGlobalColor(theme, 'grey-6')};
    `,
  },
  icon: {
    size: '22px',
    extend: (): FlattenSimpleInterpolation => css`
      margin: -2px;
    `,
  },
  size: '20px',
  gap: 'xsmall',
  extend: ({ toggle, theme }: CheckboxThemableTProps): FlattenSimpleInterpolation => {
    const totalWidth = theme?.checkBox?.toggle?.size ?? '';
    const knobWidth = theme?.checkBox?.size ?? '';
    const align = theme.checkBox?.label?.align ?? 'flex-start';

    return css`
      ${toggle && css`
        & input + span > span {
          top: 2px;
          left: 2px;
        }

        & input:checked + span > span {
          top: 2px;
          left: calc(${totalWidth} - ${knobWidth} - 2px);
        }
      `}

      align-items: ${align};
    `;
  },
  toggle: {
    background: '#bebebe',
    color: 'white',
    extend: ({
      toggle,
      checked,
    }: CheckboxThemableTProps): FlattenSimpleInterpolation | undefined =>
      toggle
        ? css`
            height: 24px;
            border: none;
            background: ${checked && '#6E6E6E'};
          `
        : undefined,
  },
  label: {
    align: 'start',
  },
};
