export const breakpoints = {
  xsmall: {
    value: 650,
  },
  small: {
    value: 950,
    edgeSize: {
      xlarge: '48px',
      large: '36px',
      medium: '24px',
      small: '16px',
      xsmall: '8px',
      xxsmall: '4px',
      hair: '1px',
    },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '4px',
      large: '6px',
      xlarge: '12px',
    },
  },
  medium: {
    value: 1100,
  },
  large: {
    value: 1750,
  },
  xlarge: {
    value: 2000,
  },
};
