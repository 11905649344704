import { css, FlattenSimpleInterpolation } from 'styled-components';

export const layer = {
  background: 'grey-1',
  border: {
    radius: '4px',
    // intelligentRounding: undefined,
  },
  container: {
    elevation: 'glow',
    zIndex: '20',
    extend: (): FlattenSimpleInterpolation =>
      css`
        overflow: hidden;
      `,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
  },
  responsiveBreakpoint: 'small', // when Layer takes over the full screen
  zIndex: '300',
};
