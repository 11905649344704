export const page = {
  wide: {
    width: {
      min: 'small',
      max: 'xlarge',
    },
    xlarge: {
      width: '1280px',
    },
  },
};
