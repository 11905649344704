export const focus = {
  // shadow or outline are required for accessibility
  border: {
    // remove to only have shadow
    color: undefined,
  },
  shadow: {
    color: 'focus',
    size: '1px',
  },
};
